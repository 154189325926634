// utils/mixpanel.js
import mixpanel from 'mixpanel-browser'

// Initialize Mixpanel
mixpanel.init('04766eabb4bbfeb5ec69701c317bfeb4')

const premiumStatus = {
  null: 'Basic',
  1: 'Basic',
  2: 'Plus',
  3: 'Premium',
  4: 'Premium Plus',
}

const gradesKeyValue = {
  1: 'Grade 8',
  2: 'Grade 9',
  3: 'Grade 10',
  4: 'Grade 11',
  5: 'Grade 12',
  8: 'In College',
  9: 'Graduated',
  7: 'Others',
}

const curriculumKeyValue = {
  1: 'IGCSE',
  2: 'IB',
  3: 'CBSE',
  4: 'ICSE',
  5: 'STATE BOARD',
  6: 'CAIE',
  7: 'UNDERGRADUATE/ DEGREE',
}

// Utility function to track events
export const trackEvent = (eventName, properties = {}, userDetails = null) => {
  mixpanel.track(eventName, {
    source: 'Website',
    ...properties,
    ...(userDetails
      ? {
          ID: userDetails.ID,
          FULL_NAME: userDetails.FIRSTNAME + ' ' + userDetails.LASTNAME,
          PHONENUMBER: userDetails.PHONENUMBER,
          EMAIL: userDetails.EMAIL,
          ISPREMIUM: userDetails.ISPREMIUM ? premiumStatus[userDetails.ISPREMIUM] : 1,
          GRADE: gradesKeyValue[userDetails.GRADE],
          CURRICULUM: curriculumKeyValue[userDetails.CURRICULUM],
          IS_PARENT: userDetails.IS_PARENT ? true : false,
        }
      : userDetails),
  })
}

// utils/authHelpers.js
export const trackEventWithAuthStatus = (auth, eventName, properties = {}) => {
  if (
    auth.isAuthenticated === true &&
    auth.loading === false &&
    auth.expiry_date !== undefined &&
    auth.userDetails.EMAIL
  ) {
    console.log("I'm authenticated")
    trackEvent(eventName, properties, auth.userDetails)
  } else if (auth.isAuthenticated === false && auth.loading === false) {
    console.log("I'm not authenticated")
    trackEvent(eventName, properties)
  }
}
