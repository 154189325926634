import { useRouter } from 'next/router'

export const usePageAttributes = () => {
  const router = useRouter()
  const currentPath = router.pathname

  function getPageId() {
    let pageId = 'home'
    switch (currentPath) {
      case '/':
        pageId = 'home'
        break
      case '/ivy-league-undergraduate-admissions':
        pageId = 'ivy-ug'
        break
      case '/ms-admissions':
        pageId = 'ms'
        break
      case '/sat-tutoring':
        pageId = 'sat-tut'
        break
      case '/ib-tutoring':
        pageId = 'ib-tut'
        break
      case '/google-form':
        pageId = 'g-form'
        break
      case '/google-ivy-league':
        pageId = 'g-ivy-league-form'
        break
    }
    return pageId
  }

  return {
    getPageId,
  }
}
