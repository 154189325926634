import React, { Fragment } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Image from 'next/image'
import { useRouter } from 'next/router'
import styles from './team-up-section.module.scss'
import { usePageAttributes } from '../../Hooks/usePageAttributes'
import Carousel from 'react-bootstrap/Carousel'
import { useSelector } from 'react-redux'
import { trackEventWithAuthStatus } from '../../utils/mixpanel'

const TeamUp = ({ choice }) => {
  const router = useRouter()
  const auth = useSelector((state) => state.auth)

  const { getPageId } = usePageAttributes()
  let buttonId = getPageId() + '-teamup'

  const redirect = () => {
    let page
    switch (router.asPath) {
      case '/ms-admissions':
        page = 'ms_advising_web'
        break
      case '/sat-tutoring':
        page = 'sat_tutoring'
        break
      case '/ib-tutoring':
        page = 'ib_tutoring_web'
        break
      case '/ivy-league-undergraduate-admissions':
        page = 'ug_advising_web'
        break
      default:
        page = 'home_web'
    }
    trackEventWithAuthStatus(auth, `click_book_consultation_teamup_${page}`, { buttonId })

    choice ? router.push('/contact/' + choice.toString()) : router.push('/contact')
  }

  return (
    <Fragment>
      <Container
        fluid
        style={{
          backgroundColor: 'rgb(26, 97, 233)',
          color: 'rgb(248, 248, 248)',
          backgroundImage: 'url("box-bg/advisors_banner_img.jpg")',
          backgroundSize: 'cover',
        }}
      >
        <Container className={styles.teamUpSection}>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12} className="p-0">
              <Row>
                <Col className={styles.advisorSection}>
                  {/* <Image
                    src="/advisor-5.jpeg"
                    width="670"
                    height="421"
                    layout="responsive"
                    className="img-responsive"
                  /> */}

                  <Carousel>
                    <Carousel.Item interval={2000}>
                      <Image
                        src="/advisor-1.jpeg"
                        width="670"
                        height="421"
                        layout="responsive"
                        className="img-responsive"
                      />
                    </Carousel.Item>
                    {/* <Carousel.Item interval={2000}>
                      <Image
                          src="/advisor-2.jpeg"
                          width="670"
                          height="421"
                          layout="responsive"
                          className="img-responsive"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <Image
                        src="/advisor-3.jpeg"
                        width="670"
                        height="421"
                        layout="responsive"
                        className="img-responsive"
                      />
                    </Carousel.Item> */}
                    <Carousel.Item interval={2000}>
                      <Image
                        src="/advisor-4.jpeg"
                        width="670"
                        height="421"
                        layout="responsive"
                        className="img-responsive"
                      />
                    </Carousel.Item>
                    <Carousel.Item interval={2000}>
                      <Image
                        src="/advisor-5.jpeg"
                        width="670"
                        height="421"
                        layout="responsive"
                        className="img-responsive"
                      />
                    </Carousel.Item>
                  </Carousel>
                </Col>
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row>
                <Col className={styles.AidenSection}>
                  <Row>
                    <Col>
                      <h2>TEAM UP WITH IVY LEAGUE-TRAINED ADMISSION ADVISORS</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3>
                        CollegePass Admission Advisors have undergone specialized college counseling training at Top US
                        Universities: Columbia University and UC Berkeley.
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {router.pathname.startsWith('/google-form') ||
                      router.pathname.startsWith('/google-ivy-league') ? (
                        <Button id={buttonId} href="#google-contact">
                          Book Your Free Consultation
                        </Button>
                      ) : (
                        <Button id={buttonId} onClick={redirect}>
                          Book Your Free Consultation
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  )
}

export default TeamUp
